<div class="fuse-vertical-navigation-wrapper">
  <!-- Header -->
  <div class="fuse-vertical-navigation-header">
    <ng-content select="[fuseVerticalNavigationHeader]"></ng-content>
  </div>

  <!-- Content -->
  <div
    class="fuse-vertical-navigation-content bg-white"
    fuseScrollbar
    [fuseScrollbarOptions]="{ wheelPropagation: inner, suppressScrollX: true }"
    #navigationContent>
    <!-- Content header -->
    <div class="fuse-vertical-navigation-content-header">
      <ng-content select="[fuseVerticalNavigationContentHeader]"></ng-content>
    </div>

    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <!-- <ng-container *ngIf="item.type === 'aside'">
                    <fuse-vertical-navigation-aside-item
                        [item]="item"
                        [name]="name"
                        [activeItemId]="activeAsideItemId"
                        [autoCollapse]="autoCollapse"
                        [skipChildren]="true"
                        (click)="toggleAside(item)"></fuse-vertical-navigation-aside-item>
                </ng-container> -->

        <!-- Basic -->
        <ng-container *ngIf="item.type === 'basic'">
          <fuse-vertical-navigation-basic-item
            [item]="item"
            [name]="name"
            [selectedItemId]="selectedItemId"
            (click)="setSelectedItem(item.id)">
          </fuse-vertical-navigation-basic-item>
        </ng-container>

        <!-- Collapsable -->
        <ng-container *ngIf="item.type === 'collapsable'">
          <fuse-vertical-navigation-collapsable-item
            [item]="item"
            [name]="name"
            [autoCollapse]="
              autoCollapse
            "></fuse-vertical-navigation-collapsable-item>
        </ng-container>

        <!-- Divider -->
        <ng-container *ngIf="item.type === 'divider'">
          <fuse-vertical-navigation-divider-item
            [item]="item"
            [name]="name"></fuse-vertical-navigation-divider-item>
        </ng-container>

        <!-- Group -->
        <ng-container *ngIf="item.type === 'group'">
          <fuse-vertical-navigation-group-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"></fuse-vertical-navigation-group-item>
        </ng-container>

        <!-- Spacer -->
        <ng-container *ngIf="item.type === 'spacer'">
          <fuse-vertical-navigation-spacer-item
            [item]="item"
            [name]="name"></fuse-vertical-navigation-spacer-item>
        </ng-container>
      </ng-container>
    </ng-container>
    <!-- projects list sidebar -->
    <div
      class="flex flex-col items-center justify-center pl-5"
      *ngIf="!navigation[0].isAdmin">
      <div class="tree-container">
        <!-- Add this div with the class -->
        <mat-tree
          class="bg-white w-full"
          [dataSource]="dataSource"
          [treeControl]="treeControl">
          <mat-tree-node
            (click)="onSubProjectClick(node)"
            class="text-slate-800 text-8px font-medium bg-white hover:bg-gray-100 rounded-lg min-w-fit cursor-pointer"
            (mouseover)="node.hover = true"
            (mouseover)="onProjectHover(node.id)"
            (mouseleave)="node.hover = false"
            *matTreeNodeDef="let node"
            matTreeNodePadding
            [routerLink]="['projects/project', node.id, 'workshops']">
            <button mat-icon-button disabled></button>

            <span
              [style.color]="
                selectedSubProject === node ? selectedColor : 'black'
              "
              class="max-w-[10rem] whitespace-normal max-h-[4rem] text-left">
              {{ node.name }}
            </span>
            <button
              [enableByRole]="['admin', 'write']"
              *ngIf="node.hover"
              mat-icon-button
              (click)="openAddSubProjectModal(node.id)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-tree-node>
          <mat-tree-node
            (click)="onProjectClick(node)"
            class="text-slate-800 text-8px font-medium bg-white hover:bg-gray-100 rounded-lg min-w-fit cursor-pointer"
            (mouseover)="node.hover = true"
            (mouseover)="onProjectHover(node.id)"
            (mouseleave)="node.hover = false"
            *matTreeNodeDef="let node; when: hasChild"
            [routerLink]="['projects/project', node.id, 'workshops']"
            matTreeNodePadding>
            <button
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{
                  treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'
                }}
              </mat-icon>
            </button>
            <span
              [style.color]="selectedProject === node ? selectedColor : 'black'"
              class="max-w-[10rem] whitespace-normal max-h-[4rem] text-left">
              {{ node.name }}
            </span>
            <button
              *ngIf="node.hover"
              mat-icon-button
              [enableByRole]="['admin', 'write']"
              (click)="openAddSubProjectModal(node.id)">
              <mat-icon>add</mat-icon>
            </button>
          </mat-tree-node>
        </mat-tree>
      </div>
      <!-- End of the tree-container div -->
    </div>

    <!--end projects list sidebar -->

    <!-- Content footer -->
    <div class="fuse-vertical-navigation-content-footer">
      <ng-content select="[fuseVerticalNavigationContentFooter]"></ng-content>
    </div>
  </div>

  <!-- Footer -->
  <div class="fuse-vertical-navigation-footer">
    <ng-content select="[fuseVerticalNavigationFooter]"></ng-content>
  </div>
</div>

<!-- Aside -->
<ng-container *ngIf="activeAsideItemId">
  <div
    class="fuse-vertical-navigation-aside-wrapper"
    fuseScrollbar
    [fuseScrollbarOptions]="{ wheelPropagation: false, suppressScrollX: true }"
    [@fadeInLeft]="position === 'left'"
    [@fadeInRight]="position === 'right'"
    [@fadeOutLeft]="position === 'left'"
    [@fadeOutRight]="position === 'right'">
    <!-- Items -->
    <ng-container *ngFor="let item of navigation; trackBy: trackByFn">
      <!-- Skip the hidden items -->
      <ng-container *ngIf="(item.hidden && !item.hidden(item)) || !item.hidden">
        <!-- Aside -->
        <ng-container
          *ngIf="item.type === 'aside' && item.id === activeAsideItemId">
          <fuse-vertical-navigation-aside-item
            [item]="item"
            [name]="name"
            [autoCollapse]="autoCollapse"></fuse-vertical-navigation-aside-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
